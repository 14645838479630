import { GenericObject } from "types";
import { createEnumParam, withDefault } from "use-query-params";
import { tuple } from "utils";

export type UsersTab = typeof usersTabs[number];

export const usersTabs = tuple("normal", "business", "blocked", "to delete");

export const UsersTabParam = withDefault(createEnumParam(usersTabs), "normal");

export const usersTabFiltersMap: Record<UsersTab, GenericObject> = {
  normal: {
    is_business_account: false,
    is_active: true,
  },
  business: {
    is_business_account: true,
    is_suspended: false,
    is_active: true,
  },
  blocked: {
    is_suspended: true,
  },
  "to delete": {
    is_active: false,
    has_delete_request: true,
  },
};
