import React, { useMemo, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useUpdateEffect } from "react-use";
import { AxiosError } from "axios";
import {
  StringParam,
  useQueryParam,
  createEnumParam,
  withDefault,
} from "use-query-params";

import { Loader, Space, SortBy, Card, Button, useNotify } from "ebs-design";

import { users } from "api";
import { useFilters, useModalState, useQueryPagination } from "hooks";
import {
  AlertErrors,
  Table,
  Pagination,
  Icon,
  DebouncedInput,
  Filters,
  TableRowLink,
  MutationConfirmModal,
  Flex,
} from "components";
import { Results, UserProfileEntity } from "types";
import { capitalize, extractOrderingOptions, resultsCount } from "utils";

import {
  UsersDashboardStats,
  userFilters,
  getUsersTableColumns,
  usersTabFiltersMap,
  usersTabs,
} from "../Users";

const Users = () => {
  const queryClient = useQueryClient();
  const pagination = useQueryPagination();
  const { limit, page, setPage } = pagination;

  const notify = useNotify();

  const [search, setSearch] = useQueryParam(
    "search",
    withDefault(StringParam, ""),
  );
  const [ordering, setOrdering] = useQueryParam(
    "ordering",
    withDefault(StringParam, ""),
  );

  const useFiltersResult = useFilters({
    filters: userFilters,
    onActiveFiltersChange: () => setPage(1),
  });

  const [currentTab, setCurrentTab] = useQueryParam(
    "tab",
    withDefault(createEnumParam(usersTabs), "normal"),
  );

  const queryParams = {
    page,
    limit,
    search,
    ordering,
    ...useFiltersResult.activeFiltersValues,
    ...usersTabFiltersMap[currentTab || "normal"],
  };

  const { isLoading, isError, data, error } = useQuery<
    Results<UserProfileEntity>,
    AxiosError
  >(["users", queryParams], () => users.getList(queryParams), {
    enabled: !!page,
  });

  const allUsersQueryParams = {
    ...usersTabFiltersMap[currentTab || "normal"],
  };

  const allUsersForThisTabQuery = useQuery<
    Results<UserProfileEntity>,
    AxiosError
  >(["all-users", allUsersQueryParams], () =>
    users.getList(allUsersQueryParams),
  );

  const blockSwitchModal = useModalState<UserProfileEntity>();
  const deleteModal = useModalState<UserProfileEntity>();
  const undeleteModal = useModalState<UserProfileEntity>();
  const bulkDeleteModal = useModalState<number[]>();

  const onMutationSuccess = () => queryClient.invalidateQueries("users");
  const onMutationError = () =>
    notify.error({
      title: "Something went wrong",
    });

  const blockSwitchMutation = useMutation(
    () =>
      users.patch(blockSwitchModal.data?.id || 0, {
        is_suspended: !blockSwitchModal.data?.is_suspended,
      }),
    {
      onSuccess: onMutationSuccess,
      onError: onMutationError,
    },
  );
  const deleteMutation = useMutation(
    () => users.delete(deleteModal.data?.id || 0),
    {
      onSuccess: onMutationSuccess,
      onError: onMutationError,
    },
  );
  const undeleteMutation = useMutation(
    () => users.patch(undeleteModal.data?.id || 0, { is_active: true }),
    {
      onSuccess: onMutationSuccess,
      onError: onMutationError,
    },
  );
  const bulkDeleteMutation = useMutation(
    () => users.bulkDelete(bulkDeleteModal.data || []),
    {
      onSuccess: () => {
        setSelectedUsers(new Set());
        onMutationSuccess();
        notify.info({
          title: "Deleting users may take some time",
          description:
            "Before all selected users will get deleted it may take some time, refresh the page later to see the updates",
        });
      },
      onError: onMutationError,
    },
  );

  useUpdateEffect(() => setPage(1), [search, ordering]);

  const [selectedUsers, setSelectedUsers] = useState(new Set<number>());

  const currentPage = useMemo(() => data?.results?.map((user) => user.id), [
    data,
  ]);
  const isPageSelected = useMemo(
    () => !!currentPage?.every((id) => selectedUsers.has(id)),
    [currentPage, selectedUsers],
  );

  const onPageSelect = () => {
    if (isPageSelected) {
      setSelectedUsers((prev) => {
        const newSet = new Set(prev);
        currentPage?.forEach((id) => newSet.delete(id));
        return newSet;
      });
    } else {
      setSelectedUsers((prev) => {
        const newSet = new Set(prev);
        currentPage?.forEach((id) => newSet.add(id));
        return newSet;
      });
    }
  };

  const onRecordSelect = (user: UserProfileEntity) =>
    setSelectedUsers((prev) => {
      const newSet = new Set(prev);
      newSet.has(user.id) ? newSet.delete(user.id) : newSet.add(user.id);
      return newSet;
    });

  const tableColumns = getUsersTableColumns({
    onBlockSwitch: blockSwitchModal.openWith,
    onDelete: deleteModal.openWith,
    onUndelete: undeleteModal.openWith,
    onPageSelect,
    onRecordSelect,
    selectable: currentTab === "to delete",
    isPageSelected,
    selectedUsers,
  });

  const onDeleteSelected = () => {
    bulkDeleteModal.openWith(Array.from(selectedUsers.values()));
  };
  const onDeleteAll = () => {
    bulkDeleteModal.openWith([]);
  };

  const orderingOptions = extractOrderingOptions(tableColumns);

  return (
    <>
      <UsersDashboardStats />
      <div className="pady-3"></div>
      <Card collapsible collapsed>
        <Card.Header>
          <Space justify="space-between">
            <div onClick={(e) => e.stopPropagation()}>
              <Space align="center" size={30}>
                <div className="fw-500">
                  Users ({resultsCount(data, isLoading)})
                </div>
                <div style={{ display: "inline-block", width: 300 }}>
                  <DebouncedInput
                    placeholder="Search in users"
                    value={search}
                    onChange={(value: string) => setSearch(value || undefined)}
                    suffix={<Icon type="search" />}
                  />
                </div>
                <SortBy
                  value={ordering}
                  onChange={(value: string) => setOrdering(value || undefined)}
                  options={orderingOptions}
                />
              </Space>
            </div>
            <div className="fw-500 cursor-pointer">Filters</div>
          </Space>
        </Card.Header>
        <Card.Body>
          <Filters filtersResult={useFiltersResult} />
        </Card.Body>
      </Card>
      <div className="pady-5"></div>
      <AlertErrors error={error} />
      {!isError && (
        <>
          <Flex>
            {usersTabs.map((tab) => (
              <Button
                type={currentTab === tab ? "primary" : "ghost"}
                onClick={() => setCurrentTab(tab)}
                className="mr-5"
              >
                {capitalize(tab)}
              </Button>
            ))}
          </Flex>
          <div className="pady-3"></div>
          <Table
            data={data?.results}
            columns={tableColumns}
            rowClassName={(value) =>
              value?.is_suspended ? "blocked-user" : ""
            }
            title={() =>
              currentTab === "to delete" && (
                <div className="padx-8 pady-4">
                  <Space justify="space-between" size="large">
                    <div>
                      {selectedUsers.size} of{" "}
                      {allUsersForThisTabQuery.data?.count || 0} selected
                    </div>

                    <Space justify="space-between">
                      <div
                        className="with-title"
                        title={
                          !selectedUsers.size
                            ? "Select some users first"
                            : undefined
                        }
                      >
                        <Button
                          onClick={onDeleteSelected}
                          type="ghost"
                          disabled={!selectedUsers.size}
                        >
                          Delete selected
                        </Button>
                      </div>

                      <div
                        className="with-title"
                        title="Delete all entries with delete request"
                      >
                        <Button onClick={onDeleteAll} type="primary">
                          Delete all
                        </Button>
                      </div>
                    </Space>
                  </Space>
                </div>
              )
            }
            footer={() => (
              <div className="padx-8 pady-4">
                <Space justify="end">
                  <Pagination pagination={pagination} results={data} />
                </Space>
              </div>
            )}
            emptyText={
              isLoading ? (
                <Loader loading />
              ) : (
                <Space className="pady-12" justify="center">
                  No data
                </Space>
              )
            }
            variants={[
              "bordered",
              "elevated",
              "dense",
              "stripped-even",
              "highlight-hover",
            ]}
            scroll={{ x: true }}
            rowKey="id"
            components={{
              body: {
                row: (props: any) => (
                  <TableRowLink {...props} makeUrl={(id) => `/users/${id}/`} />
                ),
              },
            }}
          />
        </>
      )}
      <MutationConfirmModal
        mutation={blockSwitchMutation}
        open={blockSwitchModal.isOpen}
        onClose={blockSwitchModal.close}
      >
        Are you sure to{" "}
        <b>{blockSwitchModal.data?.is_suspended ? "unblock" : "block"}</b> this
        user ?
      </MutationConfirmModal>
      <MutationConfirmModal
        mutation={deleteMutation}
        open={deleteModal.isOpen}
        onClose={deleteModal.close}
      >
        Are you sure to delete <b>{deleteModal.data?.name}</b> ?
      </MutationConfirmModal>
      <MutationConfirmModal
        mutation={undeleteMutation}
        open={undeleteModal.isOpen}
        onClose={undeleteModal.close}
      >
        Are you sure to remove the delete request for{" "}
        <b>{undeleteModal.data?.name}</b> ?
      </MutationConfirmModal>
      <MutationConfirmModal
        mutation={bulkDeleteMutation}
        open={bulkDeleteModal.isOpen}
        onClose={bulkDeleteModal.close}
      >
        Are you sure to delete {bulkDeleteModal.data?.length || "all"} users
        with delete requests?
      </MutationConfirmModal>
    </>
  );
};

export default Users;
