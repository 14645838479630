import React from "react";
import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams, useHistory } from "react-router-dom";

import { Container, Loader, useNotify } from "ebs-design";

import { AlertErrors, ConfirmModal } from "components";
import { users } from "api";
import { UserProfileEntity } from "types";

import {
  UserStats,
  UserFavourites,
  UserDetails,
  UserGifts,
  UserCompanyDetails,
} from "../UserProfile";

const UserProfile = () => {
  const id = Number(useParams<{ id: string }>().id);
  const history = useHistory();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [confirmBlockOpen, setConfirmBlockOpen] = React.useState(false);
  const [confirmUnblockOpen, setConfirmUnblockOpen] = React.useState(false);

  if (!id) history.push("/users/");

  const { data: user, isLoading, error } = useQuery<
    UserProfileEntity,
    AxiosError
  >(["users", id], () => users.getById(id || 0), {
    enabled: !!id,
  });

  const {
    mutate: mutateDelete,
    isLoading: isDeleteLoading,
    isError: isDeleteError,
    error: deleteError,
  } = useMutation<unknown, AxiosError>(() => users.delete(id), {
    onSuccess: () => {
      onMutateSuccess();
      history.push("/users/");
    },
  });

  const {
    mutate: mutateBlock,
    isLoading: isBlockLoading,
    isError: isBlockError,
    error: blockError,
  } = useMutation<unknown, AxiosError>(
    () => users.patch(id, { is_suspended: true }),
    {
      onSuccess: () => {
        onMutateSuccess();
        notify.success({
          title: "",
          description: "User was successfully unblocked.",
        });
      },
    },
  );

  const {
    mutate: mutateUnblock,
    isLoading: isUnblockLoading,
    isError: isUnblockError,
    error: unblockError,
  } = useMutation<unknown, AxiosError>(
    () => users.patch(id, { is_suspended: false }),
    {
      onSuccess: () => {
        onMutateSuccess();
        notify.success({
          title: "",
          description: "User was successfully blocked.",
        });
      },
    },
  );

  const onMutateSuccess = () => {
    queryClient.invalidateQueries(["users", id]);
    onCloseMutateModal();
  };

  const onCloseMutateModal = () => {
    setConfirmBlockOpen(false);
    setConfirmUnblockOpen(false);
    setConfirmDeleteOpen(false);
  };

  return (
    <Container size="lg">
      <AlertErrors error={error} />
      <Loader loading={isLoading} />
      {user && (
        <>
          <UserStats user={user} />
          <div className="pady-4"></div>

          <UserDetails
            user={user}
            onUnblockClick={() => setConfirmUnblockOpen(true)}
            onBlockClick={() => setConfirmBlockOpen(true)}
            onDeleteClick={() => setConfirmDeleteOpen(true)}
          />
          {user.company && (
            <>
              <div className="pady-2"></div>
              <UserCompanyDetails company={user.company} />
            </>
          )}
          <div className="pady-4"></div>

          <UserFavourites userId={user.id} />

          <div className="pady-4"></div>
          <UserGifts userId={user.id} />

          <ConfirmModal
            open={confirmDeleteOpen}
            onConfirm={() => mutateDelete()}
            onClose={onCloseMutateModal}
            onCancel={onCloseMutateModal}
            loading={isDeleteLoading}
          >
            Are you sure to delete this user?
            {isDeleteError && deleteError && (
              <AlertErrors error={deleteError} />
            )}
          </ConfirmModal>

          <ConfirmModal
            open={confirmBlockOpen}
            onConfirm={() => mutateBlock()}
            onClose={onCloseMutateModal}
            onCancel={onCloseMutateModal}
            loading={isBlockLoading}
          >
            Are you sure to block this user?
            {isBlockError && blockError && <AlertErrors error={blockError} />}
          </ConfirmModal>

          <ConfirmModal
            open={confirmUnblockOpen}
            onConfirm={() => mutateUnblock()}
            onClose={onCloseMutateModal}
            onCancel={onCloseMutateModal}
            loading={isUnblockLoading}
          >
            Are you sure want to unblock this user?
            {isUnblockError && unblockError && (
              <AlertErrors error={unblockError} />
            )}
          </ConfirmModal>
        </>
      )}
    </Container>
  );
};

export default UserProfile;
